<script setup lang="ts">
import {
  type CmsElementProductSlider,
} from "@shopware/composables";
import {useCmsElementConfig} from "#imports";
import {computed, ref} from "vue";
import ProductSlideCard from "~/components/cms/element/ProductSlideCard.vue";

const props = defineProps<{
  content: CmsElementProductSlider;
}>();
const { getConfigValue } = useCmsElementConfig(props.content);

const products = computed(() => props.content?.data?.products ?? []);
const title = computed(() => getConfigValue("title"));

import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css'

const loading = ref(true);

const settings = {
  itemsToShow: 6,
  wrapAround: true,
  snapAlign: 'start',
  autoplay: 5000,
  transition: 600,
  pauseAutoplayOnHover: true,
  breakpoints: {
    // 200px and up
    200: {
      itemsToShow: 1,
      snapAlign: 'center',
    },
    // 400px and up
    300: {
      itemsToShow: 2,
      snapAlign: 'start',
    },
    600: {
      itemsToShow: 3,
      snapAlign: 'start',
    },
    900: {
      itemsToShow: 4,
      snapAlign: 'start',
    },
    1200: {
      itemsToShow: 5,
      snapAlign: 'start',
    },
    1500: {
      itemsToShow: 6,
      snapAlign: 'start',
    },
    1900: {
      itemsToShow: 8,
      snapAlign: 'start',
    },
  },
}

const productSlider = ref(null)
const currentSlide = ref(0)

const slideTo = (val: any) => {
  currentSlide.value = val;
};

onMounted( ()=> {
  loading.value = false;
})

</script>
<template>
  <section class="" v-if="!loading">
    <div class="mb-10">
      <div  class="container container-wrap" :class="'container-'+props.content.blockId">
        <h2 v-if="title" class="mb-10">{{ title }}</h2>
      </div>
    </div>
    <div class="slider w-full overflow-auto md:overflow-x-auto whitespace-nowrap -mx-0 px-0 md:mx-0 md:px-4" :class="'space-left-'+props.content.blockId">
      <Carousel v-bind="settings" ref="productSlider" v-model="currentSlide">
        <Slide v-for="product of products" :key="product.id">
          <div class="w-full  inline-block h-full product_item_wrap">
            <ProductSlideCard :product="product"></ProductSlideCard>
          </div>
        </Slide>
      </Carousel>
    </div>
  </section>
</template>

<style scoped>
@media(max-width: 580px) {
  .slider :deep(.carousel__viewport){
    transform: none !important;
    overflow: auto !important;
    width: 100% !important;
  }
  .slider :deep(.carousel__viewport .carousel__track){
    gap: 2px;
    margin-left: -1rem !important;
    margin-right: -1rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .slider :deep(.carousel__viewport .carousel__slide){
    transform: none !important;
    width: 45vw !important;
  }
  .slider :deep(.carousel__viewport .carousel__track) {
    transform: none !important;
    margin: 0 !important;
  }
  .slider :deep(.carousel__slide--clone){
    display: none !important;
  }

}
</style>